import React from "react";
import Intro from "./Intro";
import ServicesComp from "./Services__Comp";
import "./style.css";

function Services() {
  return (
    <section className="container container__services">
      <Intro />
      <ServicesComp />
    </section>
  );
}

export default Services;
