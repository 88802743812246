import React from "react";
import Logo from "./Logo";
import Links from "./Links";

import "./style.css";
import Contact from "../Header/Contact";
import Socials from "../Header/Socials";

const Nav = () => {
  return (
    <nav>
      <div className="container container__nav">
        <Logo />
        <Links />
        {/* <Contact /> */}
        <Socials />
      </div>
    </nav>
  );
};

export default Nav;
