import React, { useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";

const About = () => {
  const [percentage] = useState(75);

  return (
    <section className="about__container">
      <div className="container ">
        <div>
          <h1>Your Gateway to Innovative IT Solutions</h1>
          <p className="text">
            We are your trusted partner in the world of technology, providing
            tailored services to transform your business. Our mission is to
            drive your success through cutting-edge IT solutions and
            personalized strategies, delivering results that propel your
            business to new heights. Let's embark on a journey to digital
            excellence together.
          </p>
          <div className="about__buttons">
            {/* <button className="btn btn-primary"> */}
            <a
              className="btn btn-primary"
              href="mailto:contact@techligator.com"
            >
              Get In Touch
            </a>
            {/* </button> */}

            <a href="#services">
              <span>Our Services</span>
              <AiOutlineArrowDown />
            </a>
          </div>
        </div>
        <div>
          <img src="/hero.svg" alt="" style={{}} />
        </div>
        {/* <div style={{ position: "relative" }}>
          <div className="about__image">
            <img
              src="https://st4.depositphotos.com/17586788/i/600/depositphotos_247586928-stock-photo-a-successful-business-girl-in.jpg"
              alt="about"
            />
          </div>
          <div className="about__progress">
            <div className="progress_bar">
              <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
            <h4>
              Business Growth
              <br />
              <small>Level is high</small>
            </h4>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default About;
