import React from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";

const Socials = () => {
  return (
    <div className="socials">
      <a
        href="https://www.instagram.com/techligator/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CgInstagram />
      </a>
      <a
        href="https://www.linkedin.com/company/techligator/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiFillLinkedin />
      </a>
      <a
        href="https://www.facebook.com/techligator/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiFillFacebook />
      </a>
    </div>
  );
};

export default Socials;
