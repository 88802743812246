import React from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillLinkedin, AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import "./style.css";

const Footer = () => {
  return (
    <>
      <footer id="contact" className="container footer__container">
        <div className="about">
          <div>
            <img
              src="./logo.png"
              alt=""
              style={{ width: "50px", cursor: "pointer" }}
            />
          </div>
          <p className="text">
            Techligator is your partner for IT success. Contact us today to
            explore how our IT solutions can elevate your business. Connect with
            us and stay updated on the latest tech trends.
          </p>
          <ul className="footer__socials">
            <li>
              <a href="https://www.facebook.com/techligator/">
                <AiFillFacebook />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/techligator/">
                <CgInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/techligator/">
                <AiFillLinkedin />
              </a>
            </li>
          </ul>
        </div>
        <div className="services">
          <h3>Services</h3>
          <ul>
            <li>
              <a href="#" className="text">
                App Devlopment
              </a>
            </li>
            <li>
              <a href="#" className="text">
                Website Devlopment
              </a>
            </li>
            <li>
              <a href="#" className="text">
                AI Model
              </a>
            </li>
            <li>
              <a href="#" className="text">
                Data Analytic
              </a>
            </li>
          </ul>
        </div>
        <div className="contact">
          <h3>Contact</h3>
          <ul>
            {/* <li>
            <address className="text">
              Office: 232 California Road Imperial
            </address>
          </li> */}
            <li>
              <a href="tel:+918260684075" className="text">
                Tel: +918260684075
              </a>
            </li>
            <li>
              <a href="mailto:contact.techligator@gmail.com" className="text">
                Email: contact.techligator@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <div className="container">
        <hr />
        <p
          className=" text"
          style={{ textAlign: "center", marginBottom: "10px !important" }}
        >
          2023 © Techligator™ All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
