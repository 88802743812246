import React from "react";

const Logo = () => {
  return (
    <div className="logo">
      <div>
        <img
          src="./logo.png"
          alt=""
          style={{ width: "50px", cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default Logo;
