import React from "react";
import { BiBrain } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function Intro() {
  return (
    <div className="services__intro" id="about">
      <div className="pos-rel">
        <div className="services__intro__image">
          <img src="./solutions.svg" alt="" />
        </div>
        {/* <Swiper
          className="services__intro__miniSlider"
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <div className="icon">
              <BiBrain />
            </div>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
          </SwiperSlide>
          <SwiperSlide>
            <div className="icon">
              <BiBrain />
            </div>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
          </SwiperSlide>
        </Swiper> */}
      </div>
      <div>
        <div className="services__intro__text">
          <h1>We are the solutions for IT problems</h1>
          <p className="text">
            We are the definitive solution to all your IT challenges. At
            Techligator, we specialize in providing comprehensive answers to
            your IT problems, offering expertise, innovation, and reliability to
            tackle even the most complex technology issues. With us, you can
            trust that your IT challenges will be transformed into opportunities
            for growth and success
          </p>
          {/* <button className="btn btn-primary">More About</button> */}
        </div>
      </div>
    </div>
  );
}

export default Intro;
