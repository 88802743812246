import React from "react";
import Work from "./Work_box";
import "./style.css";

function Portfolio() {
  const works = [
    {
      img: "https://miro.medium.com/max/1400/0*MYd28f8vpLiE34ij.jpeg",
      title: "Modern UI/UX Dashboard Design",
      text: "A stunning UI/UX dashboard design that sets new standards for user experience. This modern dashboard offers a seamless and visually appealing way to interact with your data and applications.",
    },
    {
      img: "https://i.pinimg.com/originals/80/a1/17/80a1173fb50fe01bac507532338eb336.png",
      title: "Food and Consumation Web App",
      text: "Experience the ultimate food and consumption web app, designed to satisfy your culinary cravings. Explore a wide range of culinary delights and manage your consumption with ease.",
    },
    {
      img: "https://i.pinimg.com/originals/56/ae/76/56ae76f7bc1b3e0edc962cea1af7035f.png",
      title: "Future Social Media Web 3.0 d'App",
      text: "Step into the future of social media with our Web 3.0 decentralized application. Connect, share, and engage with a global community while maintaining full control of your data and privacy.",
    },
  ];

  return (
    <section className="container container__portfolio" id="portfolio">
      {works.map((work, index) => {
        if (index === 1) {
          return (
            <React.Fragment key={index}>
              <div className="portfolio__header">
                <h1>See Our Works Or Portfolio</h1>
                <button className="btn btn-primary">More About</button>
              </div>
              <Work img={work.img} title={work.title} text={work.text} />
            </React.Fragment>
          );
        }
        return (
          <Work
            key={index}
            img={work.img}
            title={work.title}
            text={work.text}
          />
        );
      })}
    </section>
  );
}

export default Portfolio;
