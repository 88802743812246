import React from "react";
import { FcPhoneAndroid } from "react-icons/fc";
import { BsDatabaseAdd, BsLaptop, BsRobot } from "react-icons/bs";

function ServicesComp() {
  return (
    <div className="services__servicesComp" id="services">
      <div>
        <h1>Our Servcices For Technology You Need</h1>
        {/* <button className="btn btn-primary">Load More</button> */}
      </div>
      <div>
        <div>
          <div className="icon">
            <FcPhoneAndroid />
          </div>
          <h4>App Development</h4>
          <p>
            Secure your applications with our cutting-edge security solutions,
            ensuring your data and user information are protected at all times.
          </p>
        </div>
        <div>
          <div className="icon">
            <BsLaptop />
          </div>
          <h4>Website Development</h4>
          <p>
            Create stunning websites tailored to your needs, offering an
            exceptional online presence and user experience for your visitors.
          </p>
        </div>
        <div>
          <div className="icon">
            <BsRobot />
          </div>
          <h4>AI Model</h4>
          <p>
            Unlock the power of artificial intelligence with our AI models,
            providing intelligent insights and automation for your business
            processes.
          </p>
        </div>
        <div>
          <div className="icon">
            <BsDatabaseAdd />
          </div>
          <h4>Data Analytics</h4>
          <p>
            Gain valuable insights from your data with our data analytics
            services, helping you make informed decisions and drive business
            growth.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServicesComp;
